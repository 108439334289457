import { useEffect, useRef } from "react";
import styles from "../Selectable.css";
import isMobile from "../../../../util/is-mobile";

const SelectionHandles = ({
    container,
    handleSelectEnd,
    onDragStart,
    parentRef,
    range,
    selection,
    showHandles,
}) => {
    const endHandleRef = useRef();
    const startHandleRef = useRef();
    const dragState = useRef({ dragging: false });
    const SELECT_HANDLE_SIZE = isMobile.phone ? 1.3 : 1.2;

    const dragStateReset = {
        // clear out selection info
        dragging: false,
        offsetX: undefined,
        offsetY: undefined,
        range: undefined,
        target: undefined,
    };

    useEffect(() => {
        const startHandle = startHandleRef.current;
        const endHandle = endHandleRef.current;

        // relying on isDraggable from the parent is buggy due to delays in state updates,
        // so we are listening directly to the 'pointerup' event instead
        document.addEventListener("pointerup", handleDragEnd);
        startHandle.addEventListener("pointerup", handleDragEnd);
        endHandle.addEventListener("pointerup", handleDragEnd);

        // cleanup function to remove event listeners
        return () => {
            document.removeEventListener("pointerup", handleDragEnd);
            startHandle?.removeEventListener("pointerup", handleDragEnd);
            endHandle?.removeEventListener("pointerup", handleDragEnd);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        showHandles === true ? handleShowHandles() : handleHideHandles();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showHandles]);

    /* ******** Handles VISUAL Functions Start ********* */

    const handleHideHandles = () => {
        const startHandle = startHandleRef.current;
        const endHandle = endHandleRef.current;

        startHandle.style.display = "none"; // hide the handles visually
        endHandle.style.display = "none";

        startHandle.classList.remove(styles.dragging); // remove the ability to move them
        endHandle.classList.remove(styles.dragging);

        dragState.current = dragStateReset;
    };

    const isValidSelection = () => {
        if (parentRef) {
            return selection.containsNode(parentRef, true);
        }
    };

    const handleShowHandles = () => {
        if (isValidSelection() && !isMobile.apple.device) {
            const rangeRects = getValidSelectionRects();

            if (!rangeRects.length) {
                handleHideHandles();
                return;
            }

            updateHandlePosition();
        }
    };

    const getValidSelectionRects = () => {
        // DOES NOT RUN WHEN THE SELECTION CHANGES
        const rangeRects = selection.getRangeAt(0).getClientRects();
        const { left: contentLeft, right: contentRight } = document
            .querySelector("[data-aid-version]")
            .getBoundingClientRect();

        const isValidRange = ({ left, right }) =>
            left >= contentLeft && right <= contentRight;

        return [...rangeRects].filter(isValidRange);
    };

    /* ******** Handles VISUAL Functions End ********* */

    /* ******** Handles DRAG Functions Start ********* */

    const handleDragStart = (event) => {
        event.preventDefault(); // prevents default dragging of selected text
        let rect = event.currentTarget.getBoundingClientRect();

        dragState.current = {
            dragging: true,
            offsetX: event.clientX - rect.left,
            offsetY: event.clientY - rect.top,
            range: range,
            target: event.currentTarget,
        };

        dragState.current.target.setPointerCapture(event.pointerId);
        onDragStart();
    };

    const handleDrag = (event) => {
        if (dragState.current.dragging) {
            const { left: containerLeft, top: containerTop } =
                container.getBoundingClientRect();
            const { target, offsetX, offsetY } = dragState.current;

            target.style.left = event.clientX - offsetX - containerLeft + "px";
            target.style.top = event.clientY - offsetY - containerTop + "px";
            target.classList.add(styles.dragging);

            event.preventDefault();
            setRangeFromHandles(event);
            updateHandlePosition();
        }
    };

    const handleDragEnd = (event) => {
        if (dragState.current.target !== undefined) {
            dragState.current.target?.classList.remove(styles.dragging);
            dragState.current.target.releasePointerCapture(event.pointerId);
            handleSelectEnd(true);

            dragState.current = dragStateReset;
        }

        updateHandlePosition();
    };

    const updateHandlePosition = () => {
        if (isValidSelection() && !isMobile.apple.device) {
            const { left: containerLeft, top: containerTop } =
                container.getBoundingClientRect();
            const rangeRects = getValidSelectionRects();

            const startHandleStyles = startHandleRef.current.style;
            const endHandleStyles = endHandleRef.current.style;

            startHandleStyles.cssText = `
                display: block;
                height: ${SELECT_HANDLE_SIZE + "em"};
                width: ${SELECT_HANDLE_SIZE + "em"};
                left: calc(${
                    rangeRects[0].left - containerLeft
                }px - ${SELECT_HANDLE_SIZE}em);
                top: ${rangeRects[0].bottom - containerTop + "px"};
            `;

            endHandleStyles.cssText = `
                display: block;
                height: ${SELECT_HANDLE_SIZE + "em"};
                width: ${SELECT_HANDLE_SIZE + "em"};
                left: ${rangeRects.at(-1).right - containerLeft + "px"};
                top: ${rangeRects.at(-1).bottom - containerTop + "px"};
            `;
        }
    };

    const setRangeFromHandles = (event) => {
        const {
            offsetX: handleOffsetX,
            offsetY: handleOffsetY,
            target,
            range,
        } = dragState.current;
        let offsetNode, offset;

        let offsetX = event.clientX - handleOffsetX;
        let offsetY = event.clientY - handleOffsetY;

        if (target === startHandleRef.current) {
            offsetX += target.offsetWidth;
        }

        // The last caretPosition/Range often fails. This prevents the
        // undefined results from being used and causing unexpected results
        try {
            if (document.caretPositionFromPoint)
                ({ offsetNode, offset } = document.caretPositionFromPoint(
                    offsetX,
                    offsetY
                ));
            else if (document.caretRangeFromPoint)
                ({ startContainer: offsetNode, startOffset: offset } =
                    document.caretRangeFromPoint(offsetX, offsetY));
        } catch (err) {
            return;
        }

        if (offsetNode && offset) {
            target === startHandleRef.current
                ? selection.setBaseAndExtent(
                      offsetNode,
                      offset,
                      range.endContainer,
                      range.endOffset
                  )
                : selection.setBaseAndExtent(
                      range.startContainer,
                      range.startOffset,
                      offsetNode,
                      offset
                  );
        }
    };

    /* ******** Handles DRAG Functions End ********* */

    const handleProps = {
        "touch-action": "none",
        onPointerDown: handleDragStart,
        onPointerMove: handleDrag,
        onPointerUp: handleDragEnd,
    };

    return (
        <>
            <div
                className={styles.startHandle}
                ref={startHandleRef}
                {...handleProps}
            ></div>
            <div
                className={styles.endHandle}
                ref={endHandleRef}
                {...handleProps}
            ></div>
        </>
    );
};

export default SelectionHandles;
