import { memo, useState, useEffect } from "react";
import { ChatBot } from "@churchofjesuschrist/ccdops-chatbot";
import { get } from "@churchofjesuschrist/universal-env";
import { useSelectI18nStringById } from "../../../util/custom-hooks";
import { Link } from "react-router-dom";

const { OAUTH_COOKIE_NAME } = get();

// see Chatbot docs for "Customizing Links"
const LinkComponent = ({ href, children }) => {
    const relative = href.replace(new URL(href).origin, "");

    return <Link to={relative}>{children}</Link>;
};

export const AiChatBot = memo(
    ({ className, lang, linkComponent, selectI18nStringById }) => {
        const [isMobile, setIsMobile] = useState(window.innerWidth < 736);

        useEffect(() => {
            const handleResize = () => {
                setIsMobile(window.innerWidth < 736);
            };

            window.addEventListener("resize", handleResize);

            return () => {
                window.removeEventListener("resize", handleResize);
            };
        }, []);

        const chatbotStrings = {
            title: selectI18nStringById("aiChatBotTitle"),
            footer: selectI18nStringById("aiChatBotFooter"),
            learnMore: selectI18nStringById("aiChatBotLearnMore"),
            resetLabel: selectI18nStringById("aiChatBotResetLabel"),
            placeholder: selectI18nStringById("aiChatBotPlaceholder"),
            openLabel: selectI18nStringById("aiChatBotOpenLabel"),
            closeLabel: selectI18nStringById("aiChatBotCloseLabel"),
            fabText: selectI18nStringById("aiChatBotFabText"),
            legalMessage: selectI18nStringById("aiChatBotLegalMessage"),
            startMessage: selectI18nStringById("aiChatBotStartMessage"),
            resetMessage: selectI18nStringById("aiChatBotResetMessage"),
            errorRateLimited: selectI18nStringById("aiChatBotErrorRateLimited"),
            errorContentFiltered: selectI18nStringById(
                "aiChatBotErrorContentFiltered"
            ),
            errorMessageLength: selectI18nStringById(
                "aiChatBotErrorMessageLength"
            ),
            errorOther: selectI18nStringById("aiChatBotErrorOther"),
            feedbackTitle: selectI18nStringById("aiChatBotFeedbackTitle"),
            feedbackPlaceholder: selectI18nStringById(
                "aiChatBotFeedbackPlaceholder"
            ),
            feedbackSubmitted: selectI18nStringById(
                "aiChatBotFeedbackSubmitted"
            ),
        };

        // see Chatbot docs for "Analytics"
        const pageData = {
            info: {
                siteName: "Gospel Library Online",
                language: { lang },
                // info.name will be built from the page URL if it is not provided.
            },
        };

        return (
            // See Chatbot docs for more information https://github.com/ICSEng/csp-chatbot-fe/blob/main/packages/ccdops-chatbot/README.md
            <ChatBot
                className={className}
                endpoint="/study/chatbot"
                // 44px is the height of --mobile-navigation-height and 1 rem is the ChatBot default
                bottom={isMobile ? "calc(44px + 1rem)" : `1rem`}
                idCookieName={OAUTH_COOKIE_NAME}
                lang={lang}
                linkType={linkComponent}
                strings={chatbotStrings}
                doAnalytics={true}
                pageData={pageData}
            />
        );
    }
);

AiChatBot.displayName = "AiChatBot";

const AiChatBotContainer = (ownProps) => {
    let selectI18nStringById = useSelectI18nStringById();

    return (
        <AiChatBot
            {...ownProps}
            linkComponent={LinkComponent}
            selectI18nStringById={selectI18nStringById}
        />
    );
};

export default AiChatBotContainer;
