import { Fragment } from "react";
import styles from "./LDSSourceRenderer.css";
import { renderToStaticMarkup } from "react-dom/server";
import classes from "classnames";
import { getNearestSiblingWithAIDAttribute } from "../../../util/utils";
import { Title1, Title2 } from "../../theming/components/eden-headings";
import { Text1 } from "../../theming/components/eden-text";
import { domToReact, attributesToProps } from "html-react-parser";
import { Mark } from "@churchofjesuschrist/glo-highlight";
import AssociatedMediaPointer from "../AssociatedMediaPointer";
import CrossRefPointer from "../CrossRefPointer";
import FootnotePointer from "../FootnotePointer";
import AnnotationPointer from "../AnnotationPointer";
import HighlightPointer from "../HighlightPointer";
import BookmarkPointer from "../BookmarkPointer";
import HeightProvider from "../HeightProvider";
import Image from "../Image";
import SmartLink from "../SmartLink";
import RecordImpressionsButton from "../RecordImpressionsButton";
import { SelectableParagraph } from "../SelectableParagraph";
import ActionBar from "../ActionBar";
import ListTile from "../ListTile";
import InteractiveImage from "../InteractiveImage";
import { Pdf } from "@churchofjesuschrist/eden-icons";
import { Ghost } from "../../theming/components/eden-buttons/eden-buttons";
import Row from "@churchofjesuschrist/eden-row";
import { Drawer, Summary } from "@churchofjesuschrist/eden-drawer";
import BitmovinVideoPlayer from "../BitmovinVideoPlayer";

// the calculated size below removes the padding to each side of the image (88px)
const narrowReaderWidthImageSizes =
    "(max-width: 272px) 184px, (max-width: 639px) calc(100vw - 88px), (max-width: 735px) 552px, (min-width: 736px) 512px, 552px";

// This is used to find decendants of elements that can only have inline elements
const isInline = (node) => {
    // Todo: include all other elements that can only have inline elements (not just p tags)
    if (["p"].includes(node.name)) {
        return true;
    } else if (node.parent) {
        return isInline(node.parent);
    } else {
        return false;
    }
};

// TODO: update highlightTransform and crossLinkTransform to accept props (probably should use
// either useCallback or reactMemo on them) and move them to a subfolder (transforms)
export const highlightTransform = (props = {}, state = {}, internals = {}) => {
    const {
        activeAnnotationId,
        associatedMedia,
        changeActiveAnnotation,
        closePanel,
        data,
        disambiguate,
        footnotes,
        displayFootnotes,
        getFootnoteData,
        getCrossRefData,
        location,
        selectActiveAnnotation,
        selectBookmarksByPid,
        template,
        theme,
    } = props;
    const { isClient } = state;
    const { getActiveItems, syncedScrollObserver } = internals;

    const keyBuilder = {};

    let docId = data.meta.pageAttributes["data-aid"];

    const coreContentLoaded =
        isClient && document.querySelector(`[data-aid='${docId}']`);

    let activeItems = coreContentLoaded ? getActiveItems(location) : [];

    const transform = (node, replace) => {
        keyBuilder[node.name] = (keyBuilder[node.name] || 0) + 1;

        if (node.type === "tag") {
            if (!node.attribs.key) {
                node.attribs.key = node.name + keyBuilder[node.name];
            }
            if (node.attribs["data-aid"] || node.tagName === "section") {
                let children = [].concat(
                    domToReact(node.children, { replace }) || []
                );
                let pid = node.attribs["data-aid"];

                // eslint-disable-next-line no-unused-vars
                let { key, ...props } = attributesToProps(node.attribs); // need to pull `key` out of props for when props is spread below

                if (activeItems.includes(node.attribs.id)) {
                    props.className = classes(props.className, "active-item");
                }

                // If statement needed to prevent accidentally retrieving bookmarks without PIDs when pid is undefined
                if (pid) {
                    // Get all Bookmarks for this PID and turn them into BookmarkPointers
                    let bookmarkPointers = selectBookmarksByPid(pid).map(
                        ({ annotationId }) => (
                            <BookmarkPointer
                                annotationId={annotationId}
                                closePanel={closePanel}
                                disambiguate={disambiguate}
                                key={annotationId}
                            />
                        )
                    );
                    children.unshift(...bookmarkPointers);
                }

                if (associatedMedia[node.attribs["id"]]) {
                    const mediaProps = {
                        disambiguate,
                        id: props.id,
                        syncedScrollObserver,
                    };
                    const Media = (
                        <AssociatedMediaPointer
                            className={styles.associatedMediaPointer}
                            icon
                            key={props.id}
                            {...mediaProps}
                        />
                    );
                    children.unshift(Media);
                }

                return (
                    <HeightProvider Component={node.name} {...props} key={pid}>
                        {children}
                    </HeightProvider>
                );
            }

            if (node.name === "img") {
                // There is a copy of this transform in CrossRefPanel.js for images in the RCA
                // eslint-disable-next-line no-unused-vars
                const { key, ...props } = attributesToProps(node.attribs); // need to pull `key` out of props for when props is spread below
                props.inline = isInline(node);

                if (
                    props.className === "icon" ||
                    props.className === "headshot" ||
                    node.parent.name === "a"
                ) {
                    const styles = [
                        "h1",
                        "h2",
                        "h3",
                        "h4",
                        "h5",
                        "h6",
                    ].includes(node.parent.name)
                        ? { display: "inline" }
                        : {};
                    const sizes =
                        node.parent.name === "a"
                            ? narrowReaderWidthImageSizes
                            : "60px";

                    return (
                        <Image
                            {...props}
                            width={props["data-width"]}
                            height={props["data-height"]}
                            passedStyle={styles}
                            sizes={sizes}
                        />
                    );
                }

                return (
                    <InteractiveImage
                        {...props}
                        assetId={props["data-assetid"]}
                        altSource={props["data-alt-source"]}
                        height={props["data-height"]}
                        width={props["data-width"]}
                        // the calculated size below in 'sizes' removes the padding to each side of the image (88px)
                        sizes={narrowReaderWidthImageSizes}
                    />
                );
            }

            // inject expandable drawers
            if (node.attribs["data-render-instructions"] === "collapsible") {
                let children = domToReact(node.children, { replace });
                let generatedProps = attributesToProps(node.attribs);

                return (
                    <section {...generatedProps}>
                        <Drawer
                            onToggle={() => {
                                window.getSelection().collapse(null);
                                changeActiveAnnotation(null);
                            }}
                        >
                            {children}
                        </Drawer>
                    </section>
                );
            }

            // inject summary for expandable drawers
            if (
                node.name === "header" &&
                node.parent?.attribs?.["data-render-instructions"] ===
                    "collapsible"
            ) {
                const props = attributesToProps(node.attribs);

                // The summary or any of it's children should not have data-aids as they make the element annotatable
                const headerChildren = node.children.map((child) => {
                    // eslint-disable-next-line no-unused-vars
                    const { "data-aid": pid, ...remainingAttribs } =
                        child?.attribs || {};

                    return { ...child, attribs: remainingAttribs };
                });

                const children = domToReact(headerChildren, { replace });

                return <Summary {...props}>{children}</Summary>;
            }

            if (node.name === "mark") {
                const annotationId = node.attribs.annotationid;
                const activeAnnotation = activeAnnotationId === annotationId;
                // eslint-disable-next-line no-unused-vars
                const { key, ...props } = attributesToProps(node.attribs);
                const children = [].concat(
                    domToReact(node.children, { replace }) || []
                );
                const { first, pid, icon, uris: uri } = node.attribs;

                if (first && icon) {
                    const annotationProps = {
                        annotationId,
                        pid,
                        icon,
                        uri,
                        disambiguate,
                        syncedScrollObserver,
                    };

                    children.unshift(
                        <AnnotationPointer
                            icon
                            key={annotationId}
                            {...annotationProps}
                        />
                    );
                }

                return (
                    <HighlightPointer
                        annotationId={annotationId}
                        disambiguate={disambiguate}
                        key={key + annotationId}
                        onClick={() => selectActiveAnnotation()}
                    >
                        <Mark
                            annotationId={annotationId}
                            data-active-mark={activeAnnotation}
                            highlightTheme={theme}
                            key={`${annotationId}-mark`}
                            {...props}
                        >
                            {children}
                        </Mark>
                    </HighlightPointer>
                );
            }

            if (node.name === "a") {
                // eslint-disable-next-line no-unused-vars
                let { key, ...generatedProps } = attributesToProps(
                    node.attribs
                );
                const children = domToReact(node.children, { replace });

                if (node.attribs.href && /note-ref/.test(node.attribs.class)) {
                    const fid = generatedProps.href.slice(1);

                    generatedProps = {
                        ...generatedProps,
                        footnote: footnotes[fid],
                        displayFootnotes,
                        disambiguate,
                        getFootnoteData,
                        syncedScrollObserver,
                    };

                    return (
                        <FootnotePointer {...generatedProps}>
                            {children}
                        </FootnotePointer>
                    );
                } else if (
                    node.attribs.href &&
                    (/scripture-ref/.test(node.attribs.class) ||
                        /cross-ref/.test(node.attribs.class))
                ) {
                    generatedProps = {
                        ...generatedProps,
                        getCrossRefData,
                        disambiguate,
                    };
                    // To test getting a full hymn
                    // generatedProps.href = '/study/manual/hymns/master-the-tempest-is-raging.figure1';

                    return (
                        <CrossRefPointer {...generatedProps}>
                            {children}
                        </CrossRefPointer>
                    );
                } else if (/open-pdf/.test(node.attribs.class)) {
                    return (
                        <Row align="end">
                            <SmartLink {...generatedProps}>
                                <Ghost>
                                    <Pdf size="1.5rem" />
                                    {children}
                                </Ghost>
                            </SmartLink>
                        </Row>
                    );
                } else {
                    return (
                        <SmartLink {...generatedProps}>{children}</SmartLink>
                    );
                }
            }

            if (node.name === "source" && !node.attribs.src) {
                return <Fragment />;
            }

            if (node.name === "video") {
                const uniqueKey = `${node.attribs.key}-${node.attribs["data-video-id"]}`;
                const timeEnd = node.attribs["data-time-end"];
                const timeStart = node.attribs["data-time-start"];
                const durationSeconds = node.attribs["data-duration"] / 1000; // comes as milliseconds
                const duration = `${Math.floor(durationSeconds / 60)}:${(
                    durationSeconds % 60
                ).toFixed(0)}`;

                return (
                    <BitmovinVideoPlayer
                        description={node.attribs["data-video-description"]}
                        endTime={timeEnd}
                        key={uniqueKey}
                        posterId={node.attribs["data-poster-assetId"]}
                        posterUrl={node.attribs["poster"]}
                        refId={node.attribs["data-ref-id"]}
                        startTime={timeStart}
                        title={node.attribs["data-video-title"]}
                        durationLabel={duration}
                    />
                );
            }

            if (node.name === "span" && /page-break/.test(node.attribs.class)) {
                node.children = [];
            }

            if (node.attribs.class === "record-your-impressions") {
                const nearestSiblingWithAID =
                    getNearestSiblingWithAIDAttribute(node);
                const pid = nearestSiblingWithAID.attribs["data-aid"];
                const id = nearestSiblingWithAID.attribs["id"];

                return (
                    <RecordImpressionsButton
                        key={`recordImpressionsButton_${id}`}
                        pid={pid}
                        siblingId={id}
                    />
                );
            }

            if (
                node.name === "header" &&
                template === "article" &&
                node.attribs.key === "header1" &&
                node.parent === null
            ) {
                const actionBar = {
                    type: "tag",
                    name: "ActionBar",
                    parent: node,
                    children: [],
                    attribs: {},
                };

                node.children = node.children
                    .reduce((acc, child, i) => {
                        if (acc.length > i) {
                            return acc.concat(child);
                        }

                        if (child.attribs?.class === "byline") {
                            return acc.concat(child, actionBar);
                        } else if (child.attribs?.class === "kicker") {
                            return acc.concat(actionBar, child);
                        } else if (i === node.children.length - 1) {
                            return acc.concat(child, actionBar);
                        } else {
                            return acc.concat(child);
                        }
                    }, [])
                    .map((child, i, origArray) => {
                        child.next = origArray[i + 1] || null;
                        child.previous = origArray[i - 1] || null;

                        return child;
                    });

                return undefined;
            }

            if (node.name === "ActionBar") {
                return <ActionBar key="action-bar" />;
            }
        }
    };

    return transform;
};

export const crossLinkTransform = () => {
    const keyBuilder = {};

    const transform = (node, replace) => {
        keyBuilder[node.name] = (keyBuilder[node.name] || 0) + 1;

        if (node.type === "tag") {
            if (!node.attribs.key) {
                node.attribs.key = node.name + keyBuilder[node.name];
            }

            if (node.attribs["data-aid"]) {
                let children = domToReact(node.children, { replace });
                let pid = node.attribs["data-aid"];

                let props = Object.assign({}, attributesToProps(node.attribs), {
                    children,
                    key: pid,
                    pid,
                    Component: node.name,
                });

                return <SelectableParagraph {...props} />;
            }

            if (node.name === "a") {
                let generatedProps = attributesToProps(node.attribs);
                let children = domToReact(node.children, { replace });

                // only create a link if this node can be used as navigation inside the Cross Link Overlay.
                if (node.attribs.href && /note-ref/.test(node.attribs.class)) {
                    return <span>{children}</span>;
                } else {
                    return (
                        <SmartLink {...generatedProps}>{children}</SmartLink>
                    );
                }
            }

            if (node.name === "video") {
                const uniqueKey = `${node.attribs.key}-${node.attribs["data-video-id"]}`;
                const timeEnd = node.attribs["data-time-end"];
                const timeStart = node.attribs["data-time-start"];

                return (
                    <BitmovinVideoPlayer
                        description={node.attribs["data-video-description"]}
                        endTime={timeEnd}
                        key={uniqueKey}
                        posterId={node.attribs["data-poster-assetId"]}
                        posterUrl={node.attribs["poster"]}
                        refId={node.attribs["data-ref-id"]}
                        startTime={timeStart}
                        title={node.attribs["data-video-title"]}
                    />
                );
            }

            if (node.name === "img") {
                const props = attributesToProps(node.attribs);
                props.inline = isInline(node);

                if (
                    props.className === "icon" ||
                    props.className === "headshot"
                ) {
                    if (
                        ["h1", "h2", "h3", "h4", "h5", "h6"].includes(
                            node.parent.name
                        )
                    ) {
                        return (
                            <Image
                                {...props}
                                width={props["data-width"]}
                                height={props["data-height"]}
                                passedStyle={{ display: "inline" }}
                                sizes="60px"
                            />
                        );
                    }

                    return (
                        <Image
                            {...props}
                            width={props["data-width"]}
                            height={props["data-height"]}
                            sizes="60px"
                        />
                    );
                } else {
                    return (
                        // in highlightTransform this displays with the InteractiveImage component
                        // but should just be an <img> tag here
                        // also covers images in an <a> tag for crosslink mode
                        <Image
                            {...props}
                            height={props["data-height"]}
                            width={props["data-width"]}
                            sizes={narrowReaderWidthImageSizes}
                        />
                    );
                }
            }

            if (
                node.name === "sup" &&
                (node.attribs.class || "").includes("marker")
            ) {
                return <Fragment />;
            }

            if (node.name === "footer") {
                return <Fragment />;
            }
        }
    };

    return transform;
};

export const contentsTransform = () => {
    const keyBuilder = {};

    const transform = (node, replace) => {
        keyBuilder[node.name] = (keyBuilder[node.name] || 0) + 1;

        if (node.type === "tag") {
            if (!node.attribs.key) {
                node.attribs.key = node.name + keyBuilder[node.name];
            }

            if (node.name === "header" && node.parent === null) {
                const { title, subtitle, deck } = node.children.reduce(
                    (children, childNode) => {
                        if (childNode.type !== "tag") return children;

                        children[childNode.attribs.class] = domToReact([
                            childNode,
                        ]);

                        return children;
                    },
                    {}
                );

                return (
                    <header>
                        {title && <Title1>{title}</Title1>}
                        {subtitle && <Title2>{subtitle}</Title2>}
                        {deck && <Text1>{deck}</Text1>}
                    </header>
                );
            }

            if (node.name === "a") {
                // domToReact mutates the children and their attributes which ensures their attributes are React friendly.
                const children = domToReact(node.children, { replace });
                let generatedProps = attributesToProps(node.attribs);

                if (/list-tile/.test(node.attribs.class)) {
                    // We have a one-off display for the gospel-topic page. Make changes if the href is a `gospel-topic` url
                    let isTopic = /gospel-topics/.test(node.attribs.href);

                    let {
                        title,
                        primaryMeta,
                        secondaryMeta,
                        image,
                        description,
                    } = node.children.reduce((children, childNode) => {
                        if (childNode.type !== "tag") return children;

                        if (childNode.name === "img") {
                            // These are the attributes mutated by domToReact
                            children.image = childNode.attribs;
                            children.image.alt = childNode.attribs.alt || "";
                            children.image.srcSet = childNode.attribs.srcset;
                            children.image.sizes =
                                "(max-width: 381px) 72px, (max-width: 474px) 100px, (min-width: 515px) 112px, 100px";
                        } else {
                            children[childNode.attribs.class] = domToReact([
                                childNode,
                            ]);
                        }

                        return children;
                    }, {});

                    return (
                        <>
                            {
                                // If we are on a topic page, we display the `title` outside the ListTile. The glo-be passes along an `is-multiple-child` attribute on the `title` element when gospel-topic conditions are met.
                                isTopic && !title.props["is-multiple-child"] ? (
                                    <Title2 className="label label-tight">
                                        {title}
                                    </Title2>
                                ) : (
                                    ""
                                )
                            }
                            <ListTile
                                {...generatedProps}
                                key={generatedProps.key}
                                alignment="start"
                                description={renderToStaticMarkup(description)}
                                image={image}
                                primaryMeta={primaryMeta}
                                secondaryMeta={secondaryMeta}
                                title={
                                    // If we are on a topic page, we display the is-multiple-child as the title when it exists
                                    !isTopic || title.props["is-multiple-child"]
                                        ? title
                                        : ""
                                }
                            />
                        </>
                    );
                } else {
                    return (
                        <SmartLink {...generatedProps}>{children}</SmartLink>
                    );
                }
            }

            if (node.attribs.class === "label") {
                const children = domToReact(node.children, { replace });
                let generatedProps = attributesToProps(node.attribs);

                return (
                    <Title2 {...generatedProps} key={generatedProps.key}>
                        {children}
                    </Title2>
                );
            }
        }
    };

    return transform;
};
