import { Component, createRef } from "react";
import styles from "./ReaderView.css";
import classes from "classnames";

//  Utils
import analytics from "../../../util/analytics";
import isMobile from "../../../util/is-mobile";
import { ensureOnce } from "../../../util/limiters";
import { compareOffsets } from "../../../util/offset-utils";
import { isEmpty } from "../../../util/utils";

// Components
import HighlightConverter from "@churchofjesuschrist/glo-highlight-converter";
import loadable from "@loadable/component";
import FloatingAnnotationToolbar from "../../components/FloatingAnnotationToolbar";
import TagModal from "../../components/TagModal";
import ErrorPanel from "../../components/ErrorPanel";
import { iconButtonFactory } from "../../components/IconButton";
import LDSSourceRenderer from "../../components/LDSSourceRenderer";
import Loading from "../../components/Loading";
import Mask from "../../components/Mask";
import SmartLink, {
    navigate,
    shouldPreventDefault,
} from "../../components/SmartLink";
import ScrollLogic from "../../../util/template-component-scroll-utils";
import { PanelData } from "../../components/BasePointer";
import {
    ArrowBack as ArrowIcon,
    ChevronLeftLarge as ChevronLeftLargeIcon,
    ChevronRightLarge as ChevronRightLargeIcon,
} from "@churchofjesuschrist/eden-icons";
import AiChatBot from "../../components/AiChatBot";
import CrossLinkOverlay from "../../components/CrossLinkOverlay";
import Notifications from "../../components/Notifications";
import PrintOptionsModal from "../../components/PrintOptionsModal";
import NotebookModal from "../../components/NotebookModal";
import SidePanelManager from "../../components/SidePanelManager";
import ReaderViewHeader from "../../components/ReaderViewHeader";
import TableOfContentsPanel from "../../components/TableOfContentsPanel";

const AudioPlayer = loadable(
    () =>
        import(
            /* webpackChunkName: 'audio-player' */ "../../components/AudioPlayer"
        ),
    { fallback: <Loading /> }
);

const ChevronLeftLargeButton = iconButtonFactory(ChevronLeftLargeIcon);
const ChevronRightLargeButton = iconButtonFactory(ChevronRightLargeIcon);
const ArrowLeftButton = iconButtonFactory(ArrowIcon);
const ArrowRightButton = ({ ...props }) =>
    iconButtonFactory(ArrowIcon)({
        style: {
            transform: "scale(-1)",
        },
        ...props,
    });

ChevronLeftLargeButton.displayName = "ChevronLeftLargeButton";
ChevronRightLargeButton.displayName = "ChevronRightLargeButton";
ArrowLeftButton.displayName = "ArrowLeftButton";
ArrowRightButton.displayName = "ArrowRightButton";

class ReaderView extends Component {
    static defaultProps = {
        content: {
            meta: {},
        },
        location: {
            pathname: "",
        },
        panels: [],
    };

    scrollUtils = {};

    constructor(props) {
        super(props);

        const {
            book: { navList },
        } = props;
        const activeIndex = this.getActiveIndex();
        this.state = {
            tableOfContentsPanelOpen: true,
            activeUri: navList[activeIndex],
            prev: navList[activeIndex - 1],
            next: navList[activeIndex + 1],
            location: {},
            isClient: false,
            isDesktop: false,
            crossLinkOverlayOpen: false,
            notebookModalOpen: false,
            tagModalOpen: false,
            modalOpen: false,
            activeSelection: false,
        };

        this.frameRef = createRef();
        this.defaultPanelRef = createRef();
    }

    componentDidMount() {
        const {
            crossLinkMode,
            fromLibrary,
            history,
            location,
            notifications,
            selectI18nStringById,
        } = this.props;
        this.tabletMediaQueryList = window.matchMedia("(min-width: 60em)");
        this.desktopMediaQueryList = window.matchMedia("(min-width: 80em)");

        this.tabletMediaQueryList.addEventListener(
            "change",
            this.handleTabletMediaQueryList
        );
        this.desktopMediaQueryList.addEventListener(
            "change",
            this.handleDesktopMediaQueryList
        );
        document.addEventListener("keydown", this.handleKeyEvents, false);

        this.scrollUtils = new ScrollLogic({
            history,
            defaultPanelRef: this.defaultPanelRef,
            crossLinkMode,
        });
        this.selection = window.getSelection();

        let isTablet = this.tabletMediaQueryList.matches;
        let isDesktop = this.desktopMediaQueryList.matches;

        this.setState({
            isClient: true,
            isDesktop,
            isTablet,
            tableOfContentsPanelOpen: isDesktop || fromLibrary.current,
            notificationsOpen: notifications && !!notifications.length,
        });

        this.audioStrings = {
            playbackSpeed:
                selectI18nStringById("playbackSpeedTitle") || undefined,
            audioType: selectI18nStringById("audioType") || undefined,
            voiceChangeWarning:
                selectI18nStringById("voiceChangeWarningText") || undefined,
            continuousPlay:
                selectI18nStringById("continuousPlayText") || undefined,
        };

        this.fixHash = location.hash;
    }

    shouldComponentUpdate(nextProps) {
        return !isEmpty(nextProps.content);
    }

    async componentDidUpdate(prevProps, prevState) {
        const {
            activeAnnotation,
            book: { navList },
            content,
            coreContentUri,
            fromLibrary,
            history,
            loadedStyles,
            location,
            notifications,
            sidePanelActive,
        } = this.props;
        const { isClient, isDesktop, isTablet, tableOfContentsPanelOpen } =
            this.state;
        const activeIndex = this.getActiveIndex();

        // forced updated after server side render
        if (prevState.isClient !== isClient) {
            this.highlightConverter = new HighlightConverter({
                context: document.querySelector("[data-aid-version]"),
                baseUri: coreContentUri,
            });
        }

        if (this.fixHash) {
            this.fixHash = undefined;
            this.scrollUtils.scrollToHash();
        }

        const tocMaskActive =
            !isDesktop && isClient && tableOfContentsPanelOpen;
        const sidePanelMaskActive =
            !isTablet &&
            isClient &&
            !tableOfContentsPanelOpen &&
            sidePanelActive;

        if (tocMaskActive || sidePanelMaskActive) {
            this.scrollUtils.scrollHeaderOffScreen();
        }

        const changeInActiveAnnotation =
            activeAnnotation !== prevProps.activeAnnotation;
        const changeInActiveAnnotationId =
            activeAnnotation?.id !== prevProps.activeAnnotation?.id;

        // Do not want to reset/refresh the activeAnnotation when the editor panel is open because
        // it will make the editor lose focus and cause a buggy experience
        if (
            changeInActiveAnnotation &&
            (changeInActiveAnnotationId || !this.props.editorPanelOpen)
        ) {
            this.selectActiveAnnotation();
        }

        if (prevProps !== this.props) {
            let newState = {
                activeUri: navList[activeIndex],
                next: navList[activeIndex + 1],
                prev: navList[activeIndex - 1],
                notificationsOpen: notifications && !!notifications.length,
            };

            if (
                prevProps.location.pathname !== location.pathname &&
                history.action !== "REPLACE"
            ) {
                // update fromLibrary, even though we don't use it here
                fromLibrary.current = false;
                let styleName = content?.content?.head?.links?.[0]?.href || "";
                loadedStyles.includes(styleName) &&
                    location.action === "PUSH" &&
                    this.scrollUtils.resetScrollPosition();

                newState.tableOfContentsPanelOpen =
                    isDesktop && tableOfContentsPanelOpen;
            }

            if (this.state.activeUri !== newState.activeUri) {
                newState.activeOffsets = undefined;
                newState.activeRange = undefined;
                newState.activeSelection = false;
            }

            this.highlightConverter = new HighlightConverter({
                context: document.querySelector("[data-aid-version]"),
                baseUri: coreContentUri,
            });

            this.setState(newState);
        }
    }

    /*************************
     *  UTILITY FUNCTIONS
     *************************/

    fireNavigationAnalytics = (direction, href) => {
        analytics.fireNavigationEvent({
            location: "main content",
            menu: "navigation arrows",
            menuItem: direction,
            href,
        });
    };

    getActiveIndex = () => {
        let {
            book: { navList },
            location,
        } = this.props;
        let [pathname, verse] = location.pathname.split(".");
        let hash = location.hash;

        let pathnameWithHash = `${pathname}${hash}`;
        let pathnameWithVerseHash = `${pathname}#${verse}`;

        let activeIndex = navList.findIndex(
            (item) =>
                item === pathname ||
                item === pathnameWithHash ||
                item === pathnameWithVerseHash
        );

        if (activeIndex === -1) {
            activeIndex = navList.findIndex(
                (item) => item.split("#")[0] === pathname
            );
        }

        return activeIndex;
    };

    handleDesktopMediaQueryList = (event) =>
        this.setState({ isDesktop: event.matches });

    handleTabletMediaQueryList = (event) =>
        this.setState({ isTablet: event.matches });

    backToKeyPress(e) {
        e.key === "Enter" && this.backToCollection();
    }

    handleKeyEvents = ensureOnce((e) => {
        const { activePanel, dir, history, lang } = this.props;
        const { modalOpen, next, prev } = this.state;

        let leftUrl = dir === "ltr" ? prev : next;
        let rightUrl = dir === "ltr" ? next : prev;

        // Only allow page navigation when the editor panel is closed and when the top layer of the view.
        if (!modalOpen && activePanel !== "EditorPanel") {
            if ((e.key === "ArrowRight" || e.keyCode === 39) && rightUrl) {
                this.clearSelection();
                this.fireNavigationAnalytics("next", rightUrl);
                navigate(rightUrl, lang, history);
            } else if ((e.key === "ArrowLeft" || e.keyCode === 37) && leftUrl) {
                this.clearSelection();
                this.fireNavigationAnalytics("previous", leftUrl);
                navigate(leftUrl, lang, history);
            }
        }
    }, 300);

    toggleModal = (modalType) => () => {
        const crossLinkOverlayState =
            modalType === "CrossLinkOverlay" &&
            !this.state.crossLinkOverlayOpen;
        const notebookModalState =
            modalType === "NotebookModal" && !this.state.notebookModalOpen;
        const tagModalState =
            modalType === "TagModal" && !this.state.tagModalOpen;

        this.setState(
            () => ({
                crossLinkOverlayOpen: crossLinkOverlayState,
                notebookModalOpen: notebookModalState,
                tagModalOpen: tagModalState,
                modalOpen:
                    crossLinkOverlayState ||
                    notebookModalState ||
                    tagModalState,
            }),
            () => {
                if (!this.state.modalOpen && this.state.activeRange) {
                    this.selectActiveAnnotation();
                }
            }
        );
    };

    toggleSidePanel = (panel) => {
        const { activePanel, setActivePanel } = this.props;

        activePanel === panel ? setActivePanel(null) : setActivePanel(panel);

        if (panel === "StudySetsPanel" && activePanel !== panel) {
            analytics.fireStudyToolsEvent({
                component: {
                    info: { name: "study set" },
                    category: { type: "view" },
                },
            });
        }

        if (panel === "OptionsPanel" && activePanel !== panel) {
            analytics.fireComponentClickEvent({
                component: {
                    info: { name: "options" },
                    category: { primary: "site tools" },
                },
            });
        }

        if (panel === "RelatedContentPanel" && activePanel !== panel) {
            analytics.fireAssociatedRelatedContentEvent({ context: "related" });
        }
    };

    toggleTableOfContentsPanel = (force, action) => {
        const isOpen =
            typeof force === "undefined"
                ? !this.state.tableOfContentsPanelOpen
                : !!force;

        this.setState({
            tableOfContentsPanelOpen: isOpen,
        });

        analytics.fireComponentClickEvent({
            component: {
                info: { name: `table of contents|${action} drawer` },
                category: { primary: "table of contents" },
            },
        });
    };

    toggleAudioPlayer = () => {
        this.setState((state) => ({ audioPlayerOpen: !state.audioPlayerOpen }));
    };

    disambiguate = (() => {
        let timeout;
        let panels = [];
        let ids = [];

        return (panel) => {
            if (!ids.includes(panel.id)) {
                ids.push(panel.id);
                panels.push(panel);
                clearTimeout(timeout);
                timeout = setTimeout(() => {
                    // Only run handlePanels (which will expand the selection to the clicked highlight's boundaries) if there is no text selected. Selecting text indicates the user is trying to highlight new text and so we should not expand what they have selected.
                    if (!window.getSelection().toString()) {
                        this.handlePanels(panels);
                    }
                    ids = [];
                    panels = [];
                }, 100);
            }
        };
    })();

    handleAudioNavigation = () => {
        const {
            lang,
            history,
            book: { navList },
        } = this.props;
        const activeIndex = this.getActiveIndex();
        const nextAudio =
            navList[activeIndex + 1]?.includes("general-conference") &&
            navList[activeIndex + 1]?.includes("session")
                ? navList[activeIndex + 2]
                : navList[activeIndex + 1];

        if (nextAudio) {
            navigate(nextAudio, lang, history);
        }
    };

    handlePanels = (panels = []) => {
        const { addFloatingPanel, panels: currentPanels } = this.props;
        let [lastPanel = {}] = currentPanels.slice(-1);

        let combinedPanels = new PanelData(
            panels.reduce(this.reducePanels, {
                id: "",
                dataPromises: [],
                panels: [],
                addFloatingPanel,
            })
        );

        if (
            lastPanel?.id === combinedPanels.id &&
            combinedPanels.type !== "highlight"
        ) {
            return;
        }

        combinedPanels.handle();
    };

    reducePanels = (
        { id, dataPromises, type, panels, addFloatingPanel },
        panel,
        i
    ) => ({
        id: id + panel.id,
        addFloatingPanel,
        dataPromises: [...dataPromises, panel.getData()],
        panels: [...panels, panel],
        type: type ? "disambiguation" : panel.type,
        handle: i === 0 ? panel.handle : undefined,
    });

    selectActiveAnnotation = () => {
        let annotation = this.props.activeAnnotation;

        if (annotation) {
            let highlights = annotation ? annotation.highlights : [];
            let activeRange =
                this.highlightConverter.getRangeFromHighlights(highlights);

            this.setSelection(activeRange);
            this.setState({
                activeOffsets: highlights,
                activeRange,
                activeSelection: true,
            });

            if (this.props.editorPanelOpen) {
                this.props.addFloatingPanel({
                    annotationId: annotation.id,
                    autoFocus: "notes",
                    id: `${annotation.id}-editor`,
                    type: "editor",
                });
            }
        } else if (this.selection.isCollapsed) {
            this.clearSelectionState();
        } else if (this.props.editorPanelOpen) {
            this.props.removeEditorPanel();
        }
    };

    /*************************
     *  Selection
     *************************/
    setSelection(activeRange) {
        this.selection.removeAllRanges();

        // ios needs a delay so its context menu can be removed
        isMobile.apple.device
            ? setTimeout(() => this.selection.addRange(activeRange), 0)
            : this.selection.addRange(activeRange);
    }

    setOffsets = (offsets) => this.setState({ activeOffsets: offsets });

    handleSelectEnd = (range, newUserSelection) => {
        // Reader View (for debugging)
        const { activeAnnotation, updateHighlightOffsets } = this.props;
        const offsets = this.highlightConverter.getHighlightFromRange(range);
        const activeRange =
            this.highlightConverter.getRangeFromHighlights(offsets);

        if (newUserSelection) {
            this.props.changeActiveAnnotation(null);
            this.setState({
                notebookModalOpen: false,
                tagModalOpen: false,
                modalOpen: false,
            });
            this.setSelection(activeRange);
        } else if (
            activeAnnotation &&
            !compareOffsets(offsets, activeAnnotation.highlights)
        ) {
            updateHighlightOffsets(offsets)
                .then(this.selectActiveAnnotation)
                .catch(this.clearSelection);
        } else {
            this.setSelection(activeRange);
        }

        this.setState({
            activeOffsets: offsets, // offsets may be word offsets at this point, not character offsets??
            activeRange,
            activeSelection: true,
        });
    };

    clearSelectionState = (err) => {
        if (!this.state.modalOpen && !this.props.editorPanelOpen) {
            this.props.changeActiveAnnotation(null);

            this.setState({
                activeOffsets: undefined,
                activeRange: undefined,
                activeSelection: false,
            });
        }

        if (err) {
            this.setState({
                modalOpen: false,
                activeSelection: false,
                notebookModalOpen: false,
                tagModalOpen: false,
            });

            return Promise.reject(err);
        }
    };

    clearSelection = (err) => {
        this.setSelection(document.createRange());
        this.clearSelectionState(err);
    };

    /*************************
     *  CLEANUP
     *************************/
    componentWillUnmount() {
        this.desktopMediaQueryList.removeEventListener(
            "change",
            this.handleDesktopMediaQueryList
        );
        this.tabletMediaQueryList.removeEventListener(
            "change",
            this.handleTabletMediaQueryList
        );
        document.removeEventListener("keydown", this.handleKeyEvents);
        this.scrollUtils.cleanup();
        document.body.classList.remove(styles.tableOfContentsPanelOpen);
        document.body.classList.remove(styles.sidePanelManagerOpen);
    }

    /*************************
     *  RENDER
     *************************/
    render() {
        const {
            activeAnnotation,
            associatedMedia,
            audio,
            content,
            crossLinkMode,
            dir,
            editorPanelOpen,
            footnotes,
            displayFootnotes,
            fromLibrary,
            getCrossRefData,
            getFootnoteData,
            history,
            lang,
            location,
            loggedIn,
            notifications,
            selectI18nStringById,
            sidePanelActive,
            template,
        } = this.props;

        let chatBotFlag = content.meta.hasChat;

        let {
            activeOffsets,
            activeRange,
            activeSelection,
            activeUri,
            audioPlayerOpen,
            crossLinkOverlayOpen,
            isClient,
            isDesktop,
            isTablet,
            modalOpen,
            next,
            notebookModalOpen,
            notificationsOpen,
            prev,
            tableOfContentsPanelOpen,
            tagModalOpen,
        } = this.state;

        const readerClassNames = classes(
            styles.reader,
            tableOfContentsPanelOpen && styles.tableOfContentsPanelOpen,
            sidePanelActive && styles.sidePanelManagerOpen,
            !(isClient || fromLibrary.current) &&
                styles.hideMobileTableOfContents
        );

        const tocMaskActive =
            !isDesktop && isClient && tableOfContentsPanelOpen;
        const sidePanelMaskActive =
            !isTablet &&
            isClient &&
            !tableOfContentsPanelOpen &&
            sidePanelActive;

        return (
            <div
                className={readerClassNames}
                onTransitionEnd={this.handleTransitionEnd}
            >
                <Mask
                    active={tocMaskActive}
                    className={styles.mask}
                    onClick={() =>
                        this.toggleTableOfContentsPanel(false, "close")
                    }
                />
                <TableOfContentsPanel
                    activeUri={activeUri}
                    // className tableOfContentsPanel dependent on tableOfContentsPanelOpen for crossLinkOverlay
                    className={
                        tableOfContentsPanelOpen && styles.tableOfContentsPanel
                    }
                    handleVirtualScroll={this.scrollUtils.handleVirtualScroll}
                    tableOfContentsPanelOpen={tableOfContentsPanelOpen}
                    toggleTableOfContentsPanel={this.toggleTableOfContentsPanel}
                />

                <div className={styles.contentArea}>
                    <ReaderViewHeader
                        content={content}
                        crossLinkMode={crossLinkMode}
                        toggleSidePanel={this.toggleSidePanel}
                        toggleTableOfContentsPanel={
                            this.toggleTableOfContentsPanel
                        }
                    />

                    {notificationsOpen && (
                        <Notifications
                            notifications={notifications}
                            onClose={() =>
                                this.setState({ notificationsOpen: false })
                            }
                        />
                    )}

                    {content.status && (
                        <ErrorPanel
                            status={content.status}
                            className={styles.errorPanel}
                            id="readerView"
                            {...this.props}
                        />
                    )}

                    {prev && (
                        <span
                            className={classes(
                                styles.traversalLink,
                                styles.prevLink
                            )}
                        >
                            <ChevronLeftLargeButton
                                className={styles.navButton}
                                hiddenTitleText={selectI18nStringById(
                                    "previousButtonText"
                                )}
                                href={prev}
                                onClick={(event) => {
                                    this.props.changeActiveAnnotation(null);

                                    if (shouldPreventDefault(event)) {
                                        event.preventDefault();
                                        this.fireNavigationAnalytics(
                                            "previous",
                                            prev
                                        );
                                        navigate(prev, lang, history);
                                    }
                                }}
                                size="normal"
                                data-testid="previous-nav"
                            />
                        </span>
                    )}

                    {next && (
                        <span
                            className={classes(
                                styles.traversalLink,
                                styles.nextLink
                            )}
                        >
                            <ChevronRightLargeButton
                                className={styles.navButton}
                                hiddenTitleText={selectI18nStringById(
                                    "nextButtonText"
                                )}
                                href={next}
                                onClick={(event) => {
                                    this.props.changeActiveAnnotation(null);

                                    if (shouldPreventDefault(event)) {
                                        event.preventDefault();
                                        this.fireNavigationAnalytics(
                                            "next",
                                            next
                                        );
                                        navigate(next, lang, history);
                                    }
                                }}
                                size="normal"
                                data-testid="next-nav"
                            />
                        </span>
                    )}

                    {isClient && !crossLinkMode && audio && (
                        <AudioPlayer
                            audio={audio}
                            buttonClassName={styles.button}
                            className={styles.audioPlayer}
                            handleAudioNavigation={this.handleAudioNavigation}
                            open={audioPlayerOpen}
                            playerClassName={styles.player}
                            playerKey={activeUri}
                            strings={this.audioStrings}
                            togglePlayer={this.toggleAudioPlayer}
                        />
                    )}

                    {content.content && (
                        <div className={styles.contentWrapper}>
                            <LDSSourceRenderer
                                anAnnotationEditorOpen={
                                    modalOpen || editorPanelOpen
                                }
                                associatedMedia={associatedMedia}
                                changeActiveAnnotation={
                                    this.props.changeActiveAnnotation
                                }
                                className={classes(
                                    activeOffsets &&
                                        activeAnnotation &&
                                        styles.hideSelection
                                )}
                                crossLinkMode={crossLinkMode}
                                data={content}
                                defaultPanelRef={this.defaultPanelRef}
                                disambiguate={this.disambiguate}
                                footnotes={footnotes}
                                displayFootnotes={displayFootnotes}
                                frameRef={this.frameRef}
                                getCrossRefData={getCrossRefData}
                                getFootnoteData={getFootnoteData}
                                id="content"
                                key={activeUri}
                                lang={lang}
                                location={location}
                                loggedIn={loggedIn}
                                onSelectEnd={this.handleSelectEnd}
                                onSelectClear={this.clearSelection}
                                onDragStart={this.setOffsets}
                                range={activeRange}
                                selectActiveAnnotation={
                                    this.selectActiveAnnotation
                                }
                                template={template}
                            >
                                {!crossLinkMode && (
                                    <FloatingAnnotationToolbar
                                        activeSelection={activeSelection}
                                        activeUri={activeUri}
                                        clearSelection={this.clearSelection}
                                        editorPanelOpen={editorPanelOpen}
                                        modalOpen={modalOpen}
                                        offsets={activeOffsets}
                                        openCrossLinkOverlay={this.toggleModal(
                                            "CrossLinkOverlay"
                                        )}
                                        openNotebookModal={this.toggleModal(
                                            "NotebookModal"
                                        )}
                                        openTagModal={this.toggleModal(
                                            "TagModal"
                                        )}
                                        range={activeRange}
                                    />
                                )}
                                {isClient && tagModalOpen && (
                                    <TagModal
                                        container={this.frameRef.current}
                                        onClose={this.toggleModal("TagModal")}
                                        onSave={this.toggleModal("TagModal")}
                                        open={tagModalOpen}
                                        range={activeRange}
                                    />
                                )}
                                {isClient && notebookModalOpen && (
                                    <NotebookModal
                                        container={this.frameRef.current}
                                        onClose={this.toggleModal(
                                            "NotebookModal"
                                        )}
                                        onSave={this.toggleModal(
                                            "NotebookModal"
                                        )}
                                        open={notebookModalOpen}
                                        range={activeRange}
                                    />
                                )}
                            </LDSSourceRenderer>
                        </div>
                    )}

                    <div className={styles.mobileNav}>
                        <SmartLink
                            to={prev}
                            className={classes(
                                styles.mobileIcon,
                                styles.mobilePrev
                            )}
                        >
                            <ArrowLeftButton
                                dir={dir}
                                className={styles.navButton}
                                hiddenTitleText={selectI18nStringById(
                                    "previousButtonText"
                                )}
                                onClick={() => {
                                    this.fireNavigationAnalytics(
                                        "previous",
                                        prev
                                    );
                                    this.props.changeActiveAnnotation(null);
                                }}
                                data-testid="previous-nav-mobile"
                            />
                        </SmartLink>
                        <SmartLink
                            to={next}
                            className={classes(
                                styles.mobileIcon,
                                styles.mobileNext
                            )}
                        >
                            <ArrowRightButton
                                dir={dir}
                                className={styles.navButton}
                                hiddenTitleText={selectI18nStringById(
                                    "nextButtonText"
                                )}
                                onClick={() => {
                                    this.fireNavigationAnalytics("next", next);
                                    this.props.changeActiveAnnotation(null);
                                }}
                                data-testid="next-nav-mobile"
                            />
                        </SmartLink>
                    </div>
                    {loggedIn && chatBotFlag && (
                        <AiChatBot
                            className={classes(
                                styles.aiChatBot,
                                sidePanelMaskActive &&
                                    styles.hideUnderMaskandSidePanel,
                                tocMaskActive && styles.hideUnderMask
                            )}
                            lang={lang}
                        />
                    )}

                    {!crossLinkMode && (
                        <>
                            <Mask
                                active={sidePanelMaskActive}
                                className={classes(
                                    styles.mask,
                                    styles.sidePanelMask
                                )}
                                onClick={() => this.toggleSidePanel("")}
                            />
                            <SidePanelManager
                                associatedMedia={associatedMedia}
                                className={styles.sidePanelManager}
                                sidePanelBottomBufferClassName={
                                    styles.sidePanelBottomBuffer
                                }
                                defaultPanelRef={this.defaultPanelRef}
                                disambiguate={this.disambiguate}
                                handleVirtualScroll={
                                    this.scrollUtils.handleVirtualScroll
                                }
                                openCrossLinkOverlay={this.toggleModal(
                                    "CrossLinkOverlay"
                                )}
                                page="reader"
                            />
                        </>
                    )}
                </div>

                {isClient && crossLinkOverlayOpen && !crossLinkMode && (
                    <CrossLinkOverlay
                        offsets={activeOffsets}
                        onClose={this.toggleModal("CrossLinkOverlay")}
                    />
                )}

                <PrintOptionsModal />
            </div>
        );
    }
}

export default ReaderView;
