import { Component } from "react";
import debounce from "debounce";
import isMobile from "../../../util/is-mobile";
import {
    checkForMatchingRanges,
    checkForDifferentRanges,
} from "../../../util/selection-utils";
import SelectionHandles from "./components/SelectionHandles";

class Selectable extends Component {
    /* ******** Selectable SETUP Start ********* */

    static defaultProps = {
        onSelectClear: () => {},
        onSelectEnd: () => {},
        isDraggable: false,
    };

    static selectButtonDownEvents = ["pointerdown", "keydown"];
    static selectButtonUpEvents = ["pointerup", "keyup", "contextmenu"];
    static preventDefault = (e) => e.preventDefault();
    static isSelectButton = (e) =>
        (e.isPrimary && e.button === 0) ||
        e.key === "Shift" ||
        e.keyCode === 16;

    constructor(props) {
        super(props);

        // These values aren't used for rendering and using state for these values
        // causes an error to fire when the component unmounts.
        this.selectionState = {
            selectButtonDown: false,
            activeSelection: false,
        };

        this.iosRange = undefined;

        this.state = {
            showHandles: false,
        };
    }

    componentDidMount() {
        this.selection = window.getSelection();

        if (isMobile.any) {
            this.self.addEventListener(
                "contextmenu",
                Selectable.preventDefault
            );
        }

        document.addEventListener(
            "selectionchange",
            this.handleSelectionChange
        );
        Selectable.selectButtonDownEvents.forEach((event) =>
            document.addEventListener(event, this.handleSelectButtonDown)
        );
        Selectable.selectButtonUpEvents.forEach((event) =>
            document.addEventListener(event, this.handleSelectButtonUp)
        );
    }

    componentWillUnmount() {
        if (isMobile.any) {
            this.self.removeEventListener(
                "contextmenu",
                Selectable.preventDefault
            );
        }

        document.removeEventListener(
            "selectionchange",
            this.handleSelectionChange
        );
        Selectable.selectButtonDownEvents.forEach((event) =>
            document.removeEventListener(event, this.handleSelectButtonDown)
        );
        Selectable.selectButtonUpEvents.forEach((event) =>
            document.removeEventListener(event, this.handleSelectButtonUp)
        );
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            const activeRange = this.props.range && !this.props.range.collapsed;
            this.setState({ showHandles: !!activeRange });
            this.setSelectionState({ activeSelection: !!activeRange });
        }
    }

    setSelectionState = (newState) =>
        (this.selectionState = { ...this.selectionState, ...newState });

    handleSelectionChange = () => this.determineSelectionResult();

    handleSelectButtonDown = (e) => {
        const isHandle = /Handle/.test(e.target.classList); // check if the thing being clicked on is a handle

        if (isHandle) {
            return this.setSelectionState({
                selectButtonDown: Selectable.isSelectButton(e),
            });
        } else {
            this.setState({ showHandles: false });
        }
    };

    handleSelectButtonUp = (e) => {
        const { selectButtonDown } = this.selectionState;

        const selectionInContainer = this.selection?.containsNode(
            this.props.container,
            true
        );

        const validSelectButton =
            selectButtonDown &&
            (Selectable.isSelectButton(e) || e.type === "contextmenu") &&
            !e.shiftKey;

        if (selectionInContainer && validSelectButton) {
            this.setSelectionState({ selectButtonDown: false });
            this.determineSelectionResult();
        }

        this.setState({
            isDraggable: false,
        });
    };

    /* ******** Selectable SETUP End ********* */

    /* ******** Selectable FUNCTIONALITY Start ********* */

    determineNewUserSelection = () => {
        if (!this.selection.anchorNode) return;
        const isSafariDesktop = window.safari !== undefined;
        const updatedRange = this.selection?.getRangeAt(0);

        if (isSafariDesktop) {
            this.setSelectionState({
                newUserSelection: this.selection.isCollapsed,
            });
            return;
        }

        const newUserSelection =
            updatedRange &&
            checkForDifferentRanges(
                updatedRange,
                isMobile.apple.device ? this.iosRange : this.props.range
            );

        newUserSelection
            ? this.setSelectionState({ newUserSelection: true })
            : this.setSelectionState({ newUserSelection: false });
    };

    determineSelectionResult = debounce(() => {
        this.determineNewUserSelection();
        const { selectButtonDown } = this.selectionState;

        if (this.selection.isCollapsed) {
            if (this.props.anAnnotationEditorOpen) {
                // This is part of keeping the handles active when an editor/modal is open
                this.setSelectionState({ newUserSelection: false });
            } else if (
                this.selectionState.activeSelection &&
                this.selectionState.newUserSelection
            ) {
                this.handleSelectClear();
            }
        } else if (
            !selectButtonDown &&
            this.selection?.containsNode(this.props.container, true)
        ) {
            this.handleSelectEnd();
        }
    }, 300);

    handleSelectEnd(extending = false) {
        // extending: Is an existing highlight being adjusted by the user?
        const updatedRange = this.selection.getRangeAt(0);
        const isSafariDesktop = window.safari !== undefined;

        if (isMobile.apple.device) {
            // As described below, iOS Safari needs its own selection logic
            if (!checkForMatchingRanges(updatedRange, this.iosRange)) {
                this.props.onSelectEnd(
                    updatedRange,
                    this.selectionState.newUserSelection
                );
                this.iosRange = updatedRange;

                this.setSelectionState({ activeSelection: true });
            }
        } else if (isSafariDesktop) {
            // We set the value of props.range in ReaderView. Safari updates that value on it's own
            // with the current selection so we were not able to determine if there was a new selection
            // using on props.range. That is why we need a unique solution for Safari.
            if (!extending) this.setSelectionState({ newUserSelection: true });

            if (!checkForMatchingRanges(updatedRange, this.props.range)) {
                this.props.onSelectEnd(
                    updatedRange,
                    this.selectionState.newUserSelection
                );

                this.setSelectionState({ activeSelection: true });
            }
        } else if (!checkForMatchingRanges(updatedRange, this.props.range)) {
            // iOS safari does not support ::selection, so we can't use our handles, so extending
            // will never be true since it comes from our handles
            if (!isMobile.apple.device && !extending && this.props.range) {
                this.handleSelectClear();
            }

            this.props.onSelectEnd(
                // ReaderView --> handleSelectEnd:~523
                updatedRange,
                this.selectionState.newUserSelection
            );
            this.setState({ showHandles: true });
            this.setSelectionState({ activeSelection: true });
        }

        this.setSelectionState({ newUserSelection: false });
    }

    handleSelectClear() {
        this.props.onSelectClear();
        this.setState({ showHandles: false });
        this.setSelectionState({ activeSelection: false });
    }

    /* ******** Selectable FUNCTIONALITY End ********* */

    render() {
        const { children } = this.props;

        return (
            <div ref={(self) => (this.self = self)}>
                {children}
                <SelectionHandles
                    container={this.props.container}
                    handleSelectEnd={(value) => this.handleSelectEnd(value)}
                    onDragStart={this.props.onDragStart}
                    parentRef={this.self}
                    range={this.props.range}
                    selection={this.selection}
                    setSelectionState={this.setSelectionState}
                    showHandles={this.state.showHandles}
                />
            </div>
        );
    }
}

export default Selectable;
